import React from 'react'
import PropTypes from 'prop-types'
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from 'recharts'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectActiveType, selectProjects } from './selectors'
import BreakdownsTooltip from './BreakdownsTooltip'
import minutesFormatter from 'lib/minuteFormatter'

const BAR_SIZE = 60

const BreakdownProjects = ({ data, type: { symbol, label }, intl }) => {
  const fmt = minutesFormatter(intl)

  return (
    <div style={{ width: 900 }}>
      <h2>{symbol ? `${symbol} - ${label}` : `${label}`}</h2>
      <div style={{ width: '100%', height: 700, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            width={900}
            height={700}
            data={data}
            margin={{
              bottom: 300,
              left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='label'
              angle={75}
              textAnchor='start'
              dx={5}
              dy={5}
              interval={0}
            />
            <YAxis tickFormatter={fmt} />
            <Tooltip content={BreakdownsTooltip} />
            <Bar dataKey='value' fill='#FFB1A1' barSize={BAR_SIZE} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

BreakdownProjects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.shape({
    label: PropTypes.string,
    symbol: PropTypes.string
  }).isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectProjects(state),
  type: selectActiveType(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(BreakdownProjects)
