import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BreakdownsHeader from 'oee/locationBreakdowns/BreakdownsHeader'
import BreakdownsTop from './BreakdownsTop'
import BreakdownsTypes from './BreakdownsTypes'
import BreakdownProjects from './BreakdownProjects'
import { selectLatestPresentMonthKey } from './selectors'

const Breakdowns = ({ displayDetails }) => (
  <React.Fragment>
    <BreakdownsHeader />
    <BreakdownsTop />
    {displayDetails && (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <BreakdownsTypes />
        <BreakdownProjects />
      </div>
    )}
  </React.Fragment>
)

Breakdowns.propTypes = {
  displayDetails: PropTypes.any
}

const mapStateToProps = state => ({
  displayDetails: selectLatestPresentMonthKey(state)
})

export default connect(mapStateToProps)(Breakdowns)
