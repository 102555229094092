import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectTop, selectMaxTopValue } from './selectors'
import TopChart from './TopChart'
import { defineMessages, intlShape, injectIntl } from 'react-intl'
import { compose } from 'redux'

const messages = defineMessages({
  title: {
    id: 'oee.locationBreakdownsTop.title',
    defaultMessage: 'TOP 10 Stoppage Breakdowns'
  }
})

const BreakdownsTop = ({ data, maxValue, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.entries(data).map(([month, dataSet]) => (
          <TopChart
            key={month}
            month={month}
            data={dataSet}
            maxValue={maxValue}
          />
        ))}
      </div>
    </div>
  )
}

BreakdownsTop.propTypes = {
  data: PropTypes.object,
  maxValue: PropTypes.number.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectTop(state),
  maxValue: selectMaxTopValue(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(BreakdownsTop)
