import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_LOCATION: 'RECEIVE_LOCATION',
  REQUEST_TOP: 'REQUEST_TOP',
  RECEIVE_TOP: 'RECEIVE_TOP',
  REQUEST_PROJECTS: 'REQUEST_PROJECTS',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  SET_ACTIVE_TYPE: 'SET_ACTIVE_TYPE'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocation = createAction(Actions.RECEIVE_LOCATION)
export const requestTop = createAction(Actions.REQUEST_TOP)
export const receiveTop = createAction(Actions.RECEIVE_TOP)
export const requestProjects = createAction(Actions.REQUEST_PROJECTS)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
export const setActiveType = createAction(Actions.SET_ACTIVE_TYPE)
