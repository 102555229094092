import { createSelector } from 'reselect'

export const selectTimezone = state => state.config.timezone
export const selectToken = state => state.config.token
export const selectLocation = state => state.location
export const selectLocationName = state => state.location.name
export const selectTop = state => state.breakdowns.top
export const selectProjects = state => state.breakdowns.projects

export const selectMaxTopValue = createSelector(
  selectTop,
  topBreakdowns =>
    Math.max(
      ...Object.values(topBreakdowns)
        .flat()
        .map(e => e.value)
    )
)

export const selectLatestPresentMonthKey = createSelector(
  selectTop,
  topBreakdowns => {
    const keys = Object.keys(topBreakdowns).map(e => parseInt(e))
    if (!keys.length) {
      return null
    }

    return Math.max(...keys).toString()
  }
)

export const selectStoppageTypes = createSelector(
  selectTop,
  selectLatestPresentMonthKey,
  (topBreakdowns, latestKey) => {
    if (!latestKey) {
      return []
    }

    return topBreakdowns[latestKey]
  }
)

const selectOriginalActiveType = state => state.breakdowns.activeType
export const selectActiveType = createSelector(
  selectOriginalActiveType,
  selectStoppageTypes,
  (type, types) => {
    if (type) {
      return type
    } else {
      return types[0]
    }
  }
)
