import React from 'react'
import { connect } from 'react-redux'
import {
  selectStoppageTypes,
  selectActiveType,
  selectLatestPresentMonthKey
} from './selectors'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { setActiveType } from './actions'
import moment from 'moment'

const BreakdownsTypes = ({ types, active, onTypeClick, month }) => {
  return (
    <div style={{ width: 450 }}>
      <h2>
        <FormattedMessage
          id='oee.locationBreakdownsTypes.title'
          values={{ month: moment(month).format('MMMM YYYY') }}
          defaultMessage='Stoppage types from {month}'
        />
      </h2>
      <ul className='report-list'>
        {types.map(type => (
          <li
            key={type.label}
            onClick={() => onTypeClick(type)}
            className={classnames('report-list__item', {
              'report-list__item--active': active.label === type.label
            })}
          >
            {type.symbol ? `${type.symbol} - ${type.label}` : `${type.label}`}
          </li>
        ))}
      </ul>
    </div>
  )
}

BreakdownsTypes.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  ).isRequired,
  active: PropTypes.shape({ label: PropTypes.string.isRequired }),
  onTypeClick: PropTypes.func.isRequired,
  month: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  types: selectStoppageTypes(state),
  active: selectActiveType(state),
  month: selectLatestPresentMonthKey(state)
})

const mapDispatchToProps = {
  onTypeClick: setActiveType
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreakdownsTypes)
